import type { AppProps } from 'next/app';
import { ApplicationWrapper } from '@/lib/components/app/ApplicationWrapper';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';

config.autoAddCss = false;

export default function App({ Component, pageProps }: AppProps) {
  return (
    <ApplicationWrapper>
      <Component {...pageProps} />
    </ApplicationWrapper>
  );
}
