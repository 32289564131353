import { ComponentProps, FC, useCallback, useMemo } from 'react';
import { Box, RadioButton, SelectExtendedProps } from 'grommet';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormSelect } from '@/lib/components/form';
import isEqual from 'lodash.isequal';

export type RadioOption<T extends string = string> =
  | {
      id: string;
      label: string;
      value: string | number | boolean | T;
    }
  | {
      id: string;
      label: string;
      value: string | number | boolean | T;

      selectProps: SelectExtendedProps & {
        name: string;
      };
    };

type RadioGroupItemProps = ComponentProps<typeof RadioButton> & {
  option: RadioOption;
};

export const RadioGroupItem: FC<RadioGroupItemProps> = ({
  name,
  option,
  checked,
  ...props
}) => {
  const { setValue } = useFormContext();

  const hasSelect = 'selectProps' in option;
  const selectWatchName: string | undefined = hasSelect
    ? option.selectProps.name
    : undefined;

  const selectValue = useWatch({
    // @ts-expect-error foo
    name: selectWatchName,
    disabled: !hasSelect,
    defaultValue: null,
  });

  const radioValue = useWatch({
    name,
    disabled: !hasSelect,
  });

  const isSelectChecked = useMemo(() => {
    if (radioValue !== option.value) {
      return checked;
    }

    if (!('selectProps' in option)) {
      return checked;
    }

    return (
      !!option.selectProps.options.find((e) => isEqual(e, selectValue)) ||
      checked
    );
  }, [selectValue, option, checked, radioValue]);

  const onChange = useCallback(() => {
    setValue(name, option.value);
  }, [name, option, setValue]);

  const radioButton = (
    <RadioButton
      name={option.id}
      label={option.label}
      onChange={onChange}
      checked={isSelectChecked}
      {...props}
    />
  );

  if (!('selectProps' in option)) {
    return radioButton;
  }

  return (
    <Box>
      {radioButton}

      {checked && (
        <Box margin={{ top: 'small' }}>
          <FormSelect {...option.selectProps} />
        </Box>
      )}
    </Box>
  );
};
