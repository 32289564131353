import { Box, Text } from 'grommet';
import { FC } from 'react';
import { ExternalApp, useExternalAppsQuery } from '@/lib/generated/graphql';
import Link from 'next/link';
import { buildAppUrl } from '@/lib/utils';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareUpRight } from '@fortawesome/free-solid-svg-icons';

const PlainLink = styled(Link)(({ theme }) => ({
  color: theme.global.colors.text,
  textDecoration: 'none',
  '&:visited': {
    color: 'unset',
  },
}));

type ExternalAppEdge = {
  node: ExternalApp;
  __typename: 'ExternalAppEdge';
};

type ExternalAppsMenuProps = {
  category?: string;
};

export const ExternalAppsMenu: FC<ExternalAppsMenuProps> = ({ category }) => {
  const { data } = useExternalAppsQuery({
    variables: {
      first: 100,
      category,
    },
    fetchPolicy: 'cache-first',
  });

  return (
    <Box pad="small" overflow={{ vertical: 'auto' }} width={{ min: '200px' }}>
      <Box direction="column" align="start">
        {data?.externalApps?.edges
          ?.filter(
            (edge): edge is ExternalAppEdge =>
              edge?.__typename === 'ExternalAppEdge',
          )
          ?.map?.((edge) => (
            <Box key={edge.node.id} direction="row" align="center">
              <FontAwesomeIcon
                icon={faSquareUpRight}
                style={{ marginRight: '8px' }}
                size="xs"
              />

              <PlainLink
                href={buildAppUrl('shell', 'apps/{id}/', {
                  id: edge.node.id,
                })}
              >
                <Text weight="bold">{edge.node.name}</Text>
              </PlainLink>
            </Box>
          ))}
      </Box>
    </Box>
  );
};
