import { FC } from 'react';
import { TextArea, TextAreaExtendedProps } from 'grommet';
import { useController } from 'react-hook-form';
import { FormFieldLabel, FormFieldLabelProps } from './FormFieldLabel';
import { FormFieldContainer } from './FormFieldContainer';

type FormTextAreaProps = FormFieldLabelProps &
  TextAreaExtendedProps & {
    name: string;
    hidden?: boolean;
  };

export const FormTextArea: FC<FormTextAreaProps> = ({
  name,
  label,
  hidden,
  required,
  ...props
}) => {
  const { field } = useController({
    name,
    rules: {
      required,
    },
  });

  return (
    <FormFieldContainer hidden={hidden}>
      <FormFieldLabel label={label} required={required} />

      <TextArea {...props} {...field} />
    </FormFieldContainer>
  );
};
