import { ComponentProps, FC } from 'react';
import { FormRadioGroup } from '@/lib/components/form';
import { RadioGroupItem, RadioOption } from './RadioGroupItem';
import { SchedulingTime } from '@/lib/generated/graphql';

const weeklyOptions = [
  {
    label: 'Sunday',
    value: SchedulingTime.Sunday,
  },
  {
    label: 'Monday',
    value: SchedulingTime.Monday,
  },
  {
    label: 'Tuesday',
    value: SchedulingTime.Tuesday,
  },
  {
    label: 'Wednesday',
    value: SchedulingTime.Wednesday,
  },
  {
    label: 'Thursday',
    value: SchedulingTime.Thursday,
  },
  {
    label: 'Friday',
    value: SchedulingTime.Friday,
  },
  {
    label: 'Saturday',
    value: SchedulingTime.Saturday,
  },
];

const monthlyOptions = [
  {
    label: 'First Of Month',
    value: SchedulingTime.FirstOfMonth,
  },
  {
    label: '15th of Month',
    value: SchedulingTime.FifteenthOfMonth,
  },
];

const choices: RadioOption[] = [
  {
    id: 'no-schedule',
    label: 'No Schedule',
    value: false,
  },
  {
    id: 'daily',
    label: 'Daily',
    value: 'daily',
  },
  {
    id: 'weekly',
    label: 'Weekly',
    value: 'weekly',
    selectProps: {
      options: weeklyOptions,
      name: 'schedulingTime',
      labelKey: 'label',
      valueKey: {
        key: 'value',
        reduce: true,
      },
    },
  },
  {
    id: 'monthly',
    label: 'Monthly',
    value: 'monthly',
    selectProps: {
      options: monthlyOptions,
      name: 'schedulingTime',
      labelKey: 'label',
      valueKey: {
        key: 'value',
        reduce: true,
      },
    },
  },
];

type ScheduleSelectorProps = Omit<
  ComponentProps<typeof FormRadioGroup>,
  'name' | 'options'
>;

export const ScheduleSelector: FC<ScheduleSelectorProps> = (props) => (
  <FormRadioGroup {...props} label="Schedule" name="schedule" options={choices}>
    {(option: RadioOption, { checked, ...itemProps }: { checked: boolean }) => (
      <RadioGroupItem
        key={option.id}
        name="schedule"
        option={option}
        checked={checked}
        disabled={props?.disabled}
        {...itemProps}
      />
    )}
  </FormRadioGroup>
);
