import { FC } from 'react';
import { Box, RadioButtonGroup, RadioButtonGroupExtendedProps } from 'grommet';
import { FormFieldLabel, FormFieldLabelProps } from './FormFieldLabel';
import { FormFieldContainer } from './FormFieldContainer';
import { useController } from 'react-hook-form';

type FormRadioGroupProps = FormFieldLabelProps &
  RadioButtonGroupExtendedProps & {
    name: string;
    hidden?: boolean;
  };

export const FormRadioGroup: FC<FormRadioGroupProps> = ({
  name,
  label,
  required,
  hidden,
  ...radioButtonGroupProps
}) => {
  const { field } = useController({ name, rules: { required } });

  return (
    <FormFieldContainer hidden={hidden}>
      <FormFieldLabel label={label} required={required} />

      <Box margin={{ top: 'small' }}>
        <RadioButtonGroup {...radioButtonGroupProps} {...field} />
      </Box>
    </FormFieldContainer>
  );
};
