import { FC, ReactNode, useCallback, useState } from 'react';
import {
  MessageListener,
  StreamlitMessageType,
} from '@/lib/contexts/PostMessageContext/types';
import {
  CreateDatareportMutation,
  ReportSource,
} from '@/lib/generated/graphql';
import { buildAppUrl } from '@/lib/utils';
import { useRouter } from 'next/router';
import { useShellMessageListener } from '@/lib/contexts/PostMessageContext';
import { ReportPropertiesForm } from '@/lib/components/reports';
import { AIReportParams } from '@/lib/components/reports/ReportPropertiesForm/ReportForm';

type SaveAIPromptPanelProps = {
  children?: ReactNode;
};

export const SaveAIPromptPanel: FC<SaveAIPromptPanelProps> = ({ children }) => {
  const router = useRouter();
  const [formVisible, setFormVisible] = useState(false);
  const [aiReportParams, setAIReportParams] = useState<
    AIReportParams | undefined
  >(undefined);

  const onMessageReceived: MessageListener<StreamlitMessageType> = useCallback(
    (message) => {
      if ('action' in message.payload) {
        setFormVisible((v) => !v);
        return;
      }

      setAIReportParams(message.payload);
    },
    [],
  );

  const onSuccess = useCallback(
    (data: CreateDatareportMutation) => {
      void router.push(
        buildAppUrl('shell', 'reports/?id={id}', {
          id: data.createDataReport?.dataReport?.id,
        }),
      );
    },
    [router],
  );

  // Listen for streamlit messages to toggle the sidebar form
  useShellMessageListener<StreamlitMessageType>(
    'streamlit-report-page',
    'streamlit',
    onMessageReceived,
  );

  return (
    <>
      {children}
      <ReportPropertiesForm
        source={ReportSource.AiGenerated}
        sourceId="streamlit"
        visible={formVisible}
        onSuccess={onSuccess}
        onClose={() => {
          setFormVisible(false);
        }}
        params={aiReportParams}
      />
    </>
  );
};
