import { FC } from 'react';
import { Select, SelectExtendedProps } from 'grommet';
import { FormFieldLabel, FormFieldLabelProps } from './FormFieldLabel';
import { FormFieldContainer } from './FormFieldContainer';
import { useController } from 'react-hook-form';

type FormSelectProps = SelectExtendedProps &
  FormFieldLabelProps & {
    name: string;
    hidden?: boolean;
    required?: boolean;
  };

export const FormSelect: FC<FormSelectProps> = ({
  name,
  hidden,
  required,
  label,
  ...selectProps
}) => {
  const { field } = useController({
    name,
    rules: {
      required,
    },
  });

  return (
    <FormFieldContainer hidden={hidden}>
      <FormFieldLabel label={label} required={required} />

      <Select {...selectProps} value={field.value} onChange={field.onChange} />
    </FormFieldContainer>
  );
};
