import { FC } from 'react';
import { TextInput } from 'grommet';
import { useController, UseControllerProps } from 'react-hook-form';
import { FormFieldLabel, FormFieldLabelProps } from './FormFieldLabel';
import { FormFieldContainer } from './FormFieldContainer';

type FormTextInputProps = FormFieldLabelProps & {
  name: string;
  hidden?: boolean;
  rules?: UseControllerProps['rules'];
};

export const FormTextInput: FC<FormTextInputProps> = ({
  name,
  label,
  hidden,
  required,
  rules,
}) => {
  const { field } = useController({
    name,
    rules,
  });

  return (
    <FormFieldContainer hidden={hidden}>
      <FormFieldLabel label={label} required={required} />

      <TextInput {...field} />
    </FormFieldContainer>
  );
};
