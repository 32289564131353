import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button } from 'grommet';
import { ScheduleSelector } from './ScheduleSelector';
import { FormTextArea, FormTextInput } from '@/lib/components/form';
import {
  CreateDataReportMutationInput,
  ReportSource,
  SchedulingTime,
} from '@/lib/generated/graphql';

export type AIReportParams = {
  params: {
    sql: string;
    db: 'uam' | 'partners_db';
    prompt: string;
  };
  context: {
    title: string;
    fixedTimespan: boolean;
  };
};

export type ReportFormData = CreateDataReportMutationInput & {
  schedule?: string | boolean;
};

type ReportFormProps = {
  source: ReportSource;
  sourceId: string;
  params?: AIReportParams;
  onSubmit?: (data: CreateDataReportMutationInput) => Promise<void>;
  onCancel?: () => void;
};

export const ReportForm: FC<ReportFormProps> = ({
  source,
  sourceId,
  params,
  onSubmit,
  onCancel,
}) => {
  const [processing, setProcessing] = useState(false);
  const form = useForm<ReportFormData>({
    defaultValues: {
      name: '',
      description: '',
      params: {
        sql: '',
        prompt: '',
        db: 'uam',
      },
      source,
      sourceId,
      schedulingTime: null,
    },
  });

  const { setValue } = form;

  const submit = form.handleSubmit(async (data) => {
    // Cleanup scheduling selection
    if (data?.schedule === false) {
      data.schedulingTime = null;
    } else if (data?.schedule === 'daily') {
      data.schedulingTime = SchedulingTime.Daily;
    }

    delete data?.schedule;

    setProcessing(true);

    try {
      await onSubmit?.(data);
    } catch (e) {
      console.error(e);
    } finally {
      setProcessing(false);
    }
  });

  useEffect(() => {
    setValue('params', {
      sql: '',
      db: 'uam',
      prompt: '',
      ...params?.params,
    });

    setValue('name', params?.context.title ?? '');

    setValue('description', `AI prompt: ${params?.params?.prompt}`);
  }, [setValue, params]);

  return (
    <FormProvider {...form}>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          void submit();
        }}
      >
        <FormTextInput name="source" hidden />
        <FormTextInput name="sourceId" hidden />

        <FormTextInput
          label="Report Name"
          name="name"
          rules={{
            required: 'This field is required',
          }}
          required
        />

        <FormTextArea label="Description" name="description" />

        <ScheduleSelector disabled={params?.context?.fixedTimespan} />

        <FormTextInput
          name="params.sql"
          rules={{
            required: true,
          }}
          hidden
        />
        <FormTextInput
          name="params.prompt"
          rules={{
            required: true,
          }}
          hidden
        />
        <FormTextInput
          name="params.db"
          rules={{
            required: true,
          }}
          hidden
        />

        <Box
          direction="row"
          margin={{ top: 'medium' }}
          justify="between"
          gap="small"
        >
          <Button
            label="Cancel"
            style={{ flex: 1 }}
            onClick={onCancel}
            disabled={processing}
          />

          <Button
            label="Save"
            primary
            style={{ flex: 1 }}
            type="submit"
            busy={processing}
          />
        </Box>
      </form>
    </FormProvider>
  );
};
