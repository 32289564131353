import { FC } from 'react';
import { Box, Text } from 'grommet';

export type FormFieldLabelProps = {
  label?: string;
  required?: boolean;
};

export const FormFieldLabel: FC<FormFieldLabelProps> = ({
  label,
  required,
}) => {
  if (!label) {
    return null;
  }

  return (
    <Box direction="row" gap="small">
      <Text weight="bold">{label}</Text>

      {!required && <Text style={{ fontStyle: 'italic' }}>(optional)</Text>}
    </Box>
  );
};
