import { FC, useCallback } from 'react';
import { Box, Button, Layer, Text } from 'grommet';
import { Close } from 'grommet-icons';
import { AIReportParams, ReportForm } from './ReportForm';
import {
  CreateDatareportMutation,
  CreateDataReportMutationInput,
  ReportSource,
  useCreateDatareportMutation,
  useInvokeDataReportMutation,
} from '@/lib/generated/graphql';

type ReportPropertiesForm = {
  reportId?: string;
  visible?: boolean;
  onSuccess?: (data: CreateDatareportMutation) => void;
  onClose?: () => void;
  source: ReportSource;
  sourceId: string;
  params?: AIReportParams;
};

export const ReportPropertiesForm: FC<ReportPropertiesForm> = ({
  params,
  source,
  sourceId,
  visible,
  onClose,
  onSuccess,
}) => {
  const [createReport] = useCreateDatareportMutation();
  const [invokeReport] = useInvokeDataReportMutation();

  const onSubmit = useCallback(
    async (data: CreateDataReportMutationInput) => {
      const res = await createReport({
        variables: {
          input: data,
        },
      });

      onClose?.();

      if (!res?.data?.createDataReport?.dataReport?.id) {
        return;
      }

      // Run the report once on the backend
      void invokeReport({
        variables: {
          input: {
            id: res.data.createDataReport?.dataReport?.id,
          },
        },
      });

      onSuccess?.(res?.data);
    },
    [createReport, invokeReport, onSuccess, onClose],
  );

  if (!visible) {
    return null;
  }

  return (
    <Layer
      animation="slide"
      full="vertical"
      position="right"
      margin={{ top: '65px' }}
      onEsc={onClose}
      onClickOutside={onClose}
      modal={false}
    >
      <Box width={{ min: '600px' }} pad="medium">
        <Box
          direction="row"
          justify="between"
          align="start"
          margin={{ bottom: 'small' }}
        >
          <Text>REPORT SETTINGS</Text>

          <Button icon={<Close />} plain onClick={onClose} />
        </Box>

        <ReportForm
          source={source}
          sourceId={sourceId}
          onCancel={onClose}
          params={params}
          onSubmit={onSubmit}
        />
      </Box>
    </Layer>
  );
};
