import { Box, BoxExtendedProps } from 'grommet';
import { FC, ReactNode } from 'react';

type FormFieldContainerProps = BoxExtendedProps & {
  children?: ReactNode;
  hidden?: boolean;
};

export const FormFieldContainer: FC<FormFieldContainerProps> = ({
  children,
  hidden,
  ...boxProps
}) => {
  return (
    <Box
      margin={{ bottom: 'small' }}
      {...boxProps}
      style={{
        display: hidden ? 'none' : undefined,
        ...boxProps?.style,
      }}
    >
      {children}
    </Box>
  );
};
